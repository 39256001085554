import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABlaiSsmYH/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECEQMSIf/aAAgBAQABBQJUXwbE3U8ktv/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQADAAAAAAAAAAAAAAAAABAAITH/2gAIAQEABj8CLdn/xAAbEAACAwEBAQAAAAAAAAAAAAAAAREhMUFhcf/aAAgBAQABPyGos718TNvfiHorJiOoRaZ/T//aAAwDAQACAAMAAAAQmz//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABoQAQEBAQEBAQAAAAAAAAAAAAERIQBBUaH/2gAIAQEAAT8QZelLp5wR8i6Pw4iQibIHVg+8KFcGLfvHSM9G9//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3a96fdbd3423757335ec372232a509ad/e93cc/kupelna-standard.webp 300w", "/static/3a96fdbd3423757335ec372232a509ad/b0544/kupelna-standard.webp 600w", "/static/3a96fdbd3423757335ec372232a509ad/68fc1/kupelna-standard.webp 1200w", "/static/3a96fdbd3423757335ec372232a509ad/a2303/kupelna-standard.webp 1800w", "/static/3a96fdbd3423757335ec372232a509ad/4293a/kupelna-standard.webp 2400w", "/static/3a96fdbd3423757335ec372232a509ad/f4521/kupelna-standard.webp 7226w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3a96fdbd3423757335ec372232a509ad/73b64/kupelna-standard.jpg 300w", "/static/3a96fdbd3423757335ec372232a509ad/3ad8d/kupelna-standard.jpg 600w", "/static/3a96fdbd3423757335ec372232a509ad/6c738/kupelna-standard.jpg 1200w", "/static/3a96fdbd3423757335ec372232a509ad/8b34c/kupelna-standard.jpg 1800w", "/static/3a96fdbd3423757335ec372232a509ad/111a0/kupelna-standard.jpg 2400w", "/static/3a96fdbd3423757335ec372232a509ad/f1190/kupelna-standard.jpg 7226w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3a96fdbd3423757335ec372232a509ad/6c738/kupelna-standard.jpg",
              "alt": "kupelna standard",
              "title": "kupelna standard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      